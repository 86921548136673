import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { PasswordForgetLink } from '../PasswordForget';
import { SignUpLink } from '../SignUp';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';

import { Form, Button, Alert, Spinner } from 'react-bootstrap';
import './signin.scss';

const SignInPage = () => (
  <div className="signin-page">
    <SignInForm />
  </div>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  loading: false,
};


class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    this.setState({loading: true});
    const { email, password } = this.state;
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.RESTAURANT_LIST);
      })
      .catch(error => {
        this.setState({ error });
        this.setState({loading: false});
      });
    event.preventDefault();
  };


  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


  render() {
    const { email, password, error, loading } = this.state;
    const isInvalid = password === '' || email === '';
    return (
      <div className="login-container">
      <Form onSubmit={this.onSubmit}>
      <h1>Login</h1>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          name="email"
          value={email}
          onChange={this.onChange}
          type="email"
          placeholder="Enter email" />
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
      </Form.Group>
      <Form.Group controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control 
          name="password"
          value={password}
          onChange={this.onChange}
          type="password" 
          placeholder="Password" />
      </Form.Group>
      {!loading && <Button block disabled={isInvalid} variant="success" type="submit">
      <FontAwesomeIcon icon={faSignInAlt} /> Login
      </Button>}
      
      {loading && <div className="spinner-container"><Spinner  animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner></div>}
      {error &&
        <Alert variant="danger">
         <p>{error.message}</p>
        </Alert>
      }
      
      <PasswordForgetLink />
      <SignUpLink />
    </Form>
    </div>
    );
  }
}
const SignInForm = withRouter(withFirebase(SignInFormBase));

export default SignInPage;
export { SignInForm };