import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { Spinner, Button, Form, Card, Container, Row, Col, ButtonGroup, Alert } from 'react-bootstrap';
import { AddRestaurant } from './restaurantAdd.js';
import { RatingPage } from '../Rating';
import { tabbarActiveHandler } from '../Navigation/Navigation';
import './restaurantlist.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThList, faHamburger, faPlusCircle, faHome, faHeartBroken } from '@fortawesome/free-solid-svg-icons';

const INITIAL_STATE = {
  restaurantList: [],
  ratingList: [],
  error: null,
  loading: true,
  searchString: '',
};


export class RestaurantPage extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.handleChange = this.handleChange.bind(this);
  }

  // sets state, triggers render method
  handleChange(event) {
    // grab value form input box
    this.setState({ searchString: event.target.value });
  }


  componentDidMount() {
    const isFirstLogin = localStorage.getItem('firstLogin') || '';
    if (!isFirstLogin) {
      this.props.history.push(ROUTES.WELCOME);
    }

    this.setState({ loading: false });
    this.setState({ restaurantList: this.props.restaurantList });
    if (this.props.restaurantList.length > 0) {
      // load ratings 
      this.props.firebase.ratingList().on('value', snapshot => {
        const ratingObject = snapshot.val();
        const ratingList = Object.keys(ratingObject).map(key => ({
          ...ratingObject[key],
          uid: key,
        }));
        this.setState({ ratingList: ratingList });
      });
    }

  }


  // componentDidMount() {

  //   this.props.firebase.restaurantList().on('value', snapshot => {
  //     const restaurantObject = snapshot.val();
  //     // console.log(restaurantObject);
  //     // this.setState({ restaurantList: restaurantObject});
  //     // console.log(this.state.restaurantList);
  //     const restaurantList = Object.keys(restaurantObject).map(key => ({
  //       ...restaurantObject[key],
  //       uid: key,
  //     }));

  //     // get Location
  //     const apikey = "AIzaSyCsOACXxw01KIxADvyjpY9dscAmIbTfYTY"
  //     restaurantList.forEach(res => {
  //       const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${res.location}&key=${apikey}`;
  //       fetch(url)
  //         .then(res => {
  //           res.json().then(body => {
  //             console.log(body.results[0].geometry.location);
  //           })
  //         })
  //     });
  //     console.log(restaurantList);
  //     this.setState({ restaurantList: restaurantList });
  //     this.setState({ loading: false });
  //   });
  // }



  render() {
    const { loading } = this.state;
    var restaurantList = this.state.restaurantList;
    var ratingList = this.state.ratingList;
    var searchString = this.state.searchString.trim().toLowerCase();

    // filter restaurant list by value from input box
    if (searchString.length > 0) {
      restaurantList = restaurantList.filter(function (country) {
        return country.name.toLowerCase().match(searchString);
      });
    }

    return (
      <div className="restaurantpage-container">
        {loading && <div className="spinner-restaurant-container"><Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner></div>}
        {!loading &&
          <Form inline className="searchBar">
            <Form.Group controlId="formBasicSearch">
              <Form.Control size="lg" placeholder="Search Restaurant" type="text" value={this.state.searchString} onChange={this.handleChange} />
            </Form.Group>
          </Form>
        }
        {!loading && restaurantList.length > 0 &&
          <>
            <div >
              <RestaurantList restaurantList={restaurantList} ratingList={ratingList} />
            </div>
          </>}
        {!loading && restaurantList.length == 0 &&
          <>
            <NoRatingAlert></NoRatingAlert>
          </>}
      </div>
    );
  }

};

function NoRatingAlert(props) {
  return (
    <div className="alert-container">
      <Alert variant="danger">
        <Alert.Heading>Oh no <FontAwesomeIcon icon={faHeartBroken} /></Alert.Heading>
        <p>
          No Restaurants found in Database
      </p>
      </Alert>
    </div>
  )
}

const RestaurantList = ({ restaurantList, ratingList }) => (
  <Container>
    <Row md={2} lg={3}>
      {restaurantList.map(restaurant => (
        <Col key={restaurant.uid} className="center" md>
          <Card className="restaurant-card" style={{ width: '300px', color: 'black' }}>
            <Card.Body>
              <Card.Title>{restaurant.name}</Card.Title>
              <Card.Text>
                <FontAwesomeIcon size="6x" icon={faHamburger} />
                <br></br>
                <div className="rating-count">{getRatingCount(restaurant.name, ratingList)} Ratings</div>
              </Card.Text>
              <ButtonGroup size="lg" aria-label="Basic example">
                <Button to={ROUTES.RATING_LIST + '?restaurant=' + formatUrl(restaurant.name)} as={Link} variant="secondary"><FontAwesomeIcon icon={faThList} /></Button>
                <Button onClick={() => tabbarActiveHandler("rating")} to={ROUTES.RATING + '?restaurant=' + formatUrl(restaurant.name)} as={Link} variant="success"><FontAwesomeIcon icon={faPlusCircle} /></Button>
                <Button variant="secondary" target="_blank" href={restaurant.homepage_url}><FontAwesomeIcon icon={faHome} /></Button>
              </ButtonGroup>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  </Container>
);
function getRatingCount(restaurant, ratingList) {
  var ratings = [];
  ratings = ratingList.filter(rating => rating.restaurant === restaurant);
  return ratings.length;
}

function formatUrl(string) {
  return string.split('&').join('%26');
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(RestaurantPage);