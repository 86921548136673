import React, { Component } from 'react';

import { AuthUserContext, withAuthorization } from '../Session';

import PasswordChangeForm from '../PasswordChange';
import { Form, Button, Alert, Spinner } from 'react-bootstrap';
import './account.scss';

class AccountPage extends Component {



  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      user: this.props.firebase.auth.currentUser.uid,
      username: 'DEFAULT'
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.user(this.state.user).on('value', snapshot => {
      const userObject = snapshot.val();

      this.setState({
        loading: false,
        username: userObject.username,
      });
    });
  }

 

  render() {
    const { username, loading } = this.state;
    return (
      
      <div className="account-page">
        {loading && <div className="spinner-container">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
            </Spinner>
            </div>}
        {!loading && 
          <div className="account-page">
            <h1 className="account-headline">Hello {username}</h1>
            <h3 className="account-center">You can change your Password if you want</h3>
            <PasswordChangeForm />
          </div>

        }
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(AccountPage);