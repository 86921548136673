import React from 'react';
import { withFirebase } from '../Firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';

import './SignOut.css'

import { Button } from 'react-bootstrap';

const SignOutButton = ({ firebase }) => (
  <Button className="button-logout" variant="outline-danger" onClick={firebase.doSignOut}>
    <FontAwesomeIcon icon={faSignInAlt} /> Logout</Button>
);

export default withFirebase(SignOutButton);