import React, { Component } from 'react';
import './landing.css'
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { Button } from 'react-bootstrap';

import { withRouter } from 'react-router-dom';

export class Landing extends Component {

  constructor(props) {
    super(props);
    if (this.props.authUser) {
      this.props.history.push(ROUTES.RESTAURANT_LIST);
    }
  }


  render() {
    return (
      <div className="circle-container">
        <div className="circles">
          <div></div>
          <div></div>
          <div></div>
          <span></span>

        </div>
        <h1 className="landing-headline">Powerd by REACT</h1>
        <Link className="button-login" to={ROUTES.SIGN_IN}>
          <Button variant="outline-success" >Go to Login</Button>
        </Link>
      </div>
    );
  }

}
// const Landing = () => (
//   <div className="circle-container">
//     <div className="circles">
//     <div></div>
//     <div></div>
//     <div></div>
//   <span></span>

// </div>
// <h1 className="landing-headline">Powerd by REACT</h1>
// <Link className="button-login" to={ROUTES.SIGN_IN}>
// <Button variant="outline-success" >Go to Login</Button>
// </Link>
//   </div>

// );


export default withRouter(Landing);