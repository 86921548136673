import React from 'react';
import { Link } from 'react-router-dom';
// import PageNotFound from '../assets/images/PageNotFound';

import * as ROUTES from '../../constants/routes';

import './notfound.scss';

class NotFoundPage extends React.Component {
    render() {
        return <div className="notfound-page">
            {/* <img src={PageNotFound}  /> */}
            {/* <p style={{textAlign:"center"}}>
              <Link to={ROUTES.HOME}>Go to Home </Link>
            </p> */}
            <figure>
                <div className="sad-mac"></div>
                <figcaption>
                    <span className="sr-text">Error 404: Not Found</span>
                    <span className="e"></span>
                    <span className="r"></span>
                    <span className="r"></span>
                    <span className="o"></span>
                    <span className="r"></span>
                    <span className="_4"></span>
                    <span className="_0"></span>
                    <span className="_4"></span>
                    <span className="n"></span>
                    <span className="o"></span>
                    <span className="t"></span>
                    <span className="f"></span>
                    <span className="o"></span>
                    <span className="u"></span>
                    <span className="n"></span>
                    <span className="d"></span>
                </figcaption>
            </figure>
        </div>;
    }
}

export default NotFoundPage;