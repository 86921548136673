import React, {Component} from 'react';
import Map from './burger_map.png';
import GoogleMap from './googlemap.js';
import './burgermap.scss';
import { withAuthorization } from '../Session';
import { Spinner, Button, Alert, Card, Container, Row, Col } from 'react-bootstrap';

const INITIAL_STATE = {
    restaurantList: [],
    error: null,
    loading: true,
  };

export class BurgermapPage extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }
  
    componentDidMount() {
        
        this.setState( {restaurantList: this.props.restaurantList});
        if (this.props.restaurantList.length != 0) {
            this.setState({ loading:false });
        }
    }

    render() {
        const { loading, restaurantList } = this.state;
      return (
        <div className="burgermap-page">
        {/* <h1>Burger Map Stuttgart</h1>
        <BurgerMapImage /> */}
        {loading && <div className="spinner-restaurant-container"><Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner></div>}
        {!loading && <GoogleMap restaurantList={restaurantList} />}
        </div>
      );
  }
 
}


const BurgerMapImage = () => (
    <div className="burger-image-container">
        <img className="burger-image" src={Map}></img>
    </div> 
);

const condition = authUser => !!authUser;
// export default withAuthorization(condition)(HomePage);
export default  withAuthorization(condition)(BurgermapPage);