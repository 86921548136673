import React, { Component } from 'react';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import { Form, Button, Alert, Spinner, Modal } from 'react-bootstrap';
import { withAuthorization } from '../Session';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faMapMarkerAlt, faHamburger, faPlusCircle, faAtom, faDizzy, faGrinHearts, faMehBlank, faFrownOpen, faSmile } from '@fortawesome/free-solid-svg-icons';

import './rating.scss';

const INITIAL_STATE = {
  restaurantList: [],
  initalRestaurant: '',
  restaurant: '',
  burger: '',
  comment: '',
  ambienceRating: 0,
  serviceRating: 0,
  rating: 0,
  userId: '',
  error: null,
  loading: true,
  success: false,

};

export class RatingPage extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    // get query params
    const params = new URLSearchParams(props.location.search);
    const currentRestaurant = params.get('restaurant');
    if (currentRestaurant === null) {
    } else {
      // set to current state
      this.state.initalRestaurant = currentRestaurant;
      this.state.restaurant = currentRestaurant;
    }
  }



  componentDidMount() {

    this.setState({ restaurantList: this.props.restaurantList });
    if (this.props.restaurantList.length != 0) {
      this.setState({ loading: false, userId: this.props.firebase.auth.currentUser.uid });
    }
  }

  getRestaurantUid(restaurantName) {
    return this.props.restaurantList.find(({ name }) => name === restaurantName).uid;
  }

  onSubmit = event => {
    console.log(this.state);
    this.props.firebase
      .rating(this.props.firebase.create_UUID())
      .set({
        restaurantId: this.getRestaurantUid(this.state.restaurant),
        restaurant: this.state.restaurant,
        rating: this.state.rating,
        serviceRating: this.state.serviceRating,
        ambienceRating: this.state.ambienceRating,
        burger: this.state.burger,
        comment: this.state.comment,
        userId: this.state.userId,
        date: this.getCurrentDate(),
          })
      .then(() => {
        console.log('success');
        this.setState({ success: true });
      });
  }


  getCurrentDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;

    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return dd + '.' + mm + '.' + yyyy;
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  setRating(event) {
    console.log(event.target.value);
    this.setState({ rating: event.target.value });
  }
  render() {
    const { success, restaurant, initalRestaurant, homepage_url, error, loading, restaurantList } = this.state;
    return (
      <>
        {loading && <div className="spinner-restaurant-container"><Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner></div>}
        {!loading &&
          <div className="rating-container">
            <Form onSubmit={this.onSubmit}>
              <h1><FontAwesomeIcon icon={faPlusCircle} /> Add Rating</h1>


              {initalRestaurant !== '' &&
                <Form.Group controlId="formBasicRestaurantTex">
                  <Form.Label>Restaurant</Form.Label>
                  <Form.Control
                    disabled={true}
                    name="restaurant"
                    value={restaurant}
                    type="text"
                  />
                </Form.Group>
              }
              {initalRestaurant === '' &&
                <Form.Group controlId="exampleForm.RestaurantSelect">
                  <Form.Label>Restaurant</Form.Label>
                  <Form.Control name="restaurant" onChange={this.onChange} as="select">
                    <option key="choose">Please Choose</option>
                    {restaurantList.map(restaurant => (
                      <option key={restaurant.uid} value={restaurant.id}>{restaurant.name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              }


              <Form.Group controlId="formBasicBurger">
                <Form.Label>Burger</Form.Label>
                <Form.Control
                  name="burger"
                  onChange={this.onChange}
                  type="text"
                />
              </Form.Group>
              <Form.Group controlId="formBasicRangeCustom">
                <Form.Label>Burger Rating</Form.Label>
                <h2 className="rating-headling">{this.state.rating}
                  {this.state.rating > 79 && <FontAwesomeIcon icon={faGrinHearts} />}
                  {(this.state.rating > 59 && this.state.rating < 80) && <FontAwesomeIcon icon={faSmile} />}
                  {(this.state.rating > 39 && this.state.rating < 60) && <FontAwesomeIcon icon={faMehBlank} />}
                  {(this.state.rating > 19 && this.state.rating < 40) && <FontAwesomeIcon icon={faFrownOpen} />}
                  {this.state.rating < 20 && <FontAwesomeIcon icon={faDizzy} />}
                </h2>
                <Form.Control name="rating" type="range" onChange={this.onChange} custom />

              </Form.Group>
              <Form.Group controlId="formBasicServiceRangeCustom">
                <Form.Label>Service Rating</Form.Label>
                <h2 className="rating-headling">{this.state.serviceRating}
                  {this.state.serviceRating > 79 && <FontAwesomeIcon icon={faGrinHearts} />}
                  {(this.state.serviceRating > 59 && this.state.serviceRating < 80) && <FontAwesomeIcon icon={faSmile} />}
                  {(this.state.serviceRating > 39 && this.state.serviceRating < 60) && <FontAwesomeIcon icon={faMehBlank} />}
                  {(this.state.serviceRating > 19 && this.state.serviceRating < 40) && <FontAwesomeIcon icon={faFrownOpen} />}
                  {this.state.serviceRating < 20 && <FontAwesomeIcon icon={faDizzy} />}
                </h2>
                <Form.Control name="serviceRating" type="range" onChange={this.onChange} custom />

              </Form.Group>
              <Form.Group controlId="formBasicAmbienceRangeCustom">
                <Form.Label>Ambience Rating</Form.Label>
                <h2 className="rating-headling">{this.state.ambienceRating}
                  {this.state.ambienceRating > 79 && <FontAwesomeIcon icon={faGrinHearts} />}
                  {(this.state.ambienceRating > 59 && this.state.ambienceRating < 80) && <FontAwesomeIcon icon={faSmile} />}
                  {(this.state.ambienceRating > 39 && this.state.ambienceRating < 60) && <FontAwesomeIcon icon={faMehBlank} />}
                  {(this.state.ambienceRating > 19 && this.state.ambienceRating < 40) && <FontAwesomeIcon icon={faFrownOpen} />}
                  {this.state.ambienceRating < 20 && <FontAwesomeIcon icon={faDizzy} />}
                </h2>
                <Form.Control name="ambienceRating" type="range" onChange={this.onChange} custom />

              </Form.Group>
              <Form.Group controlId="formBasicComment">
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  name="comment"
                  value={homepage_url}
                  onChange={this.onChange}
                  type="text"
                  as="textarea" rows="5"
                />
              </Form.Group>
              <Button block variant="success" onClick={this.onSubmit}>
                ADD
        </Button>

              {error &&
                <Alert variant="danger">
                  <p>{error.message}</p>
                </Alert>
              }
            </Form>

            <SuccessModal
              show={success}
              onHide={() => {
                this.setState({ success: false })
                this.props.history.push(ROUTES.RESTAURANT_LIST);
              }}
            ></SuccessModal>
          </div>}
      </>
    )
  }
}



function SuccessModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Successfully
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img className="success-image" src="./source.gif"></img>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="dark" className="success-button" onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}


const condition = authUser => !!authUser;
export default withAuthorization(condition)(withFirebase(RatingPage));