import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';


import { Form, Button, Alert, Spinner } from 'react-bootstrap';
import './passwordforget.scss';


const PasswordForgetPage = () => (
  <div className="passwordforget-page">
    <PasswordForgetForm />
  </div>
);

const INITIAL_STATE = {
  email: '',
  error: null,
  loading: false,
  success: false,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  
  onSubmit = event => {
    this.setState({loading: true});
    const { email } = this.state;
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        
        this.setState({ ...INITIAL_STATE });
        // this.setState({loading: false});
        this.setState({success: true});
      })
      .catch(error => {
        this.setState({loading: false});
        this.setState({ error });
      });
    event.preventDefault();
  };
  
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error, loading, success } = this.state;
    const isInvalid = email === '';
    return (
      <div className="login-container">
      <Form onSubmit={this.onSubmit}>
      <h1>Password Forget</h1>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Email Address</Form.Label>
        <Form.Control
          name="email"
          value={this.state.email}
          onChange={this.onChange}
          type="email"
          placeholder="Enter email" />
        <Form.Text className="text-muted">
          E-Mail for resetting your Password.
        </Form.Text>
      </Form.Group>
      {success &&
        <Alert variant="success">
         <p>Password reset is send to your E-Mail</p>
        </Alert>
      }
      {!loading && <Button block disabled={isInvalid} variant="success" type="submit">
      Reset My Password
      </Button>}
      {loading && <div className="spinner-container"><Spinner  animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner></div>}
      {error &&
        <Alert variant="danger">
         <p>{error.message}</p>
        </Alert>
      }
       <Link to={ROUTES.SIGN_IN}>
            <Button className="back-button" block variant="dark">
              Back
      </Button>
          </Link>

    </Form>
    </div>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };