import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import { withFirebase } from '../Firebase';
import InfoWindowEx from './InfoWindowEx'

import { tabbarActiveHandler } from '../Navigation/Navigation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThList, faHamburger, faPlusCircle, faWifi, faMapMarkedAlt, faHome } from '@fortawesome/free-solid-svg-icons';

import * as ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';
import { Spinner, Button, Alert, Card, ButtonGroup, Row, Col } from 'react-bootstrap';

const mapStyles = {
  width: '100%',
  height: 'calc(100vh - 56px)',
  color: 'black',
};

const infoWindowStyles = {
  color: 'black',
  background: '#000000'
}

const INITIAL_STATE = {
  restaurantList: [],
  showingInfoWindow: false,
  activeMarker: {},
  selectedPlace: {},
  activeRestaurant: {},
};

export class GoogleMap extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    if (this.props.restaurantList.length != 0) {
      this.setState({ restaurantList: this.props.restaurantList });
    }

  }


  onMarkerClick = (props, marker, e) => {
    this.setState({ 
      selectedPlace: props, 
      activeMarker: marker, 
      showingInfoWindow: true,
      activeRestaurant: marker.name,
     });
  }

  goToGoogleMap = (props) => {
    //open window to navigate to restaurant
    window.open("https://maps.google.com?q="+this.state.activeRestaurant.geometry.lat+","+this.state.activeRestaurant.geometry.lng );
  
  }

  goToHomepage = (props) => {
    window.open(this.state.activeRestaurant.homepage_url);
  }

  goToAddRating = (props) => {
    tabbarActiveHandler("rating");
    this.props.history.push(ROUTES.RATING + '?restaurant=' + formatUrl(this.state.activeRestaurant.name));
  }

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };

  render() {
    const { restaurantList } = this.state;
    return (

      <Map

        google={this.props.google}
        zoom={13}
        style={mapStyles}
        initialCenter={{ lat: 48.7756174, lng: 9.1808129 }}
        streetViewControl={false}
        mapTypeControl={false}
        onClick={this.onMapClicked}
      >

        {restaurantList.map(restaurant => (

          <Marker
            onClick={this.onMarkerClick}
            key={restaurant.uid}
            title={restaurant}
            name={restaurant}
            position={{ lat: restaurant.geometry.lat, lng: restaurant.geometry.lng }} >


          </Marker>
        ))}
        <InfoWindowEx
          style={infoWindowStyles}
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}>
          <div>
            <h4>{this.state.activeRestaurant.name}</h4>
        <p>Addresse: {this.state.activeRestaurant.location}</p>
            {/* <Button onClick={this.goToGoogleMap}>Zum Restaurant</Button> */}
            <ButtonGroup size="lg" aria-label="Basic example">
                <Button variant="secondary" onClick={this.goToHomepage}><FontAwesomeIcon icon={faHome} /></Button>
                <Button variant="success" onClick={this.goToAddRating}><FontAwesomeIcon icon={faPlusCircle} /></Button>

                <Button variant="secondary" onClick={this.goToGoogleMap}><FontAwesomeIcon icon={faMapMarkedAlt} /></Button>
              </ButtonGroup>
          </div>
        </InfoWindowEx>
      </Map>
    )
  }
}

function formatUrl(string) {
  return string.split('&').join('%26');
}
export default withRouter(withFirebase(GoogleApiWrapper({
  apiKey: 'AIzaSyAvr9l2BXbmuIvi9bOithJChQhXx_GiPFk'
})(GoogleMap)));
// old key :
// AIzaSyCsOACXxw01KIxADvyjpY9dscAmIbTfYTY