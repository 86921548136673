import React, { Component } from 'react';

import { AuthUserContext, withAuthorization } from '../Session';

import * as ROUTES from '../../constants/routes';
import PasswordChangeForm from '../PasswordChange';
import { Form, Button, Alert, Spinner, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeartBroken, faUtensils, faHeart, faHamburger, faCity, faSearchLocation } from '@fortawesome/free-solid-svg-icons';
import './welcome.scss';

class WelcomePage extends Component {



    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            user: this.props.firebase.auth.currentUser.uid,
            username: 'DEFAULT'
        };
        this.goToRestaurantList = this.goToRestaurantList.bind(this);
    }

    componentDidMount() {
        this.setState({ loading: true });

        this.props.firebase.user(this.state.user).on('value', snapshot => {
            const userObject = snapshot.val();

            this.setState({
                loading: false,
                username: userObject.username,
            });
        });
    }

    goToRestaurantList() {
        // set firstlogin property
        localStorage.setItem('firstLogin', false);
        this.props.history.push(ROUTES.RESTAURANT_LIST);
    }

    render() {
        const { username, loading } = this.state;
        return (
            <div className="welcomepage-container">
                {loading && <div className="spinner-welcome-container"><Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner></div>}
                {!loading &&
                    <div className="">
                        <Modal
                            size="lg"
                            show={true}
                            onHide={() => this.goToRestaurantList}
                            aria-labelledby="example-modal-sizes-title-lg"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-lg">
                                    Welcome {username} <FontAwesomeIcon icon={faHeart} />
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <img className="success-image" src="./hello.gif"></img>
                            <h3>Have fun eating <FontAwesomeIcon icon={faUtensils} /> and rating <FontAwesomeIcon icon={faSearchLocation} /> new Burgers <FontAwesomeIcon icon={faHamburger} /> around Stuttgart <FontAwesomeIcon icon={faCity} /></h3>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="success" className="start-button" onClick={this.goToRestaurantList}>Lets start</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>}
            </div>
        );
    }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(WelcomePage);