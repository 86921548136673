import React, { Component } from 'react';
import * as ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { Card, Button, Alert, Spinner, Modal,Row, Col, Container} from 'react-bootstrap';
import { withAuthorization } from '../Session';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeartBroken, faPlusCircle, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import './ratingList.scss';

const INITIAL_STATE = {
    ratingList: [],
    userList: [],
    restaurant: '',
    burger: '',
    comment: '',
    serviceRating: 0,
    ambienceRating: 0,
    rating: 0,
    userId: '',
    error: null,
    loading: true,
    success: false,

};

export class RatingListPage extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
        // get query params
        const params = new URLSearchParams(props.location.search);
        const currentRestaurant = params.get('restaurant');
        if (currentRestaurant === null) {
        } else {
            this.state.restaurant = currentRestaurant;
        }
    }



    componentDidMount() {
        // get Ratings
        if (this.state.restaurant !== '') {
            // load ratings 
            this.props.firebase.ratingList().on('value', snapshot => {
                const ratingObject = snapshot.val();
                const ratingList = Object.keys(ratingObject).map(key => ({
                    ...ratingObject[key],
                    uid: key,
                }));
                const list = ratingList.filter(rating => rating.restaurant === this.state.restaurant);
                this.setState({ ratingList: list });
                this.setState({  userId: this.props.firebase.auth.currentUser.uid });

                //load users 
                // this.props.firebase
                this.props.firebase.users().on('value', snapshot => {
                    const userObject = snapshot.val();
                    const userList = Object.keys(userObject).map(key => ({
                        ...userObject[key],
                        uid: key,
                    }));
                    this.setState({ userList: userList, loading: false});
                });

            });
        }
    }

    getRestaurantUid(restaurantName) {
        return this.props.restaurantList.find(({ name }) => name === restaurantName).uid;
    }
    E
    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { restaurant, ratingList, userList, error, loading } = this.state;
        return (<div className="ratinglistpage-container">
            <h1 className="ratingPage-Headline">{restaurant}</h1>
            {!loading && ratingList.length > 0 &&
            <div className="button-container">
            <Link to={ROUTES.RESTAURANT_LIST}>
            <Button variant="dark" className="back-button" ><FontAwesomeIcon icon={faChevronLeft} /> Back</Button>
            </Link>
            </div>
            }
           {loading && <div className="spinner-ratinglist-container"><Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner></div>}
            {!loading && ratingList.length == 0 &&
                <>
                    <NoRatingAlert restaurant={restaurant}></NoRatingAlert>
                </>}
            {!loading && ratingList.length > 0 && 
                <Container><Row md={2} lg={3}>
                    
              
                  {ratingList.map(rating => (
                       
                    <RatingCard key={rating.uid} 
                    rating={rating} 
                    userList={userList}></RatingCard>
                  ))}
                    </Row>
                </Container>
            }
        </div>
        );
    }
}

function RatingCard(props) {
    const rating = props.rating;
    console.log(rating);
    const serviceRating = rating.serviceRating;
    const ambienceRating = rating.ambienceRating;
    //get username
    const username = props.userList.find(userObject => userObject.uid === rating.userId).username;
    // cardcolor

    return (
        <Col key={rating.uid} className="center" md>
        <Card
      bg={getCardColor(rating.rating)}
      key={rating.uid}
      text={'white'}
      className="rating-card"
      style={{ width: '300px',  position: 'relative' }}
    >
      <Card.Header>Reviewer: {username}</Card.Header>
      <Card.Body>
        <Card.Title style={{ 'fontSize': '1.5rem', }}>Date: {rating.date}</Card.Title>
        <Card.Text style={{ 'textAlign': 'left', 'fontSize': '1.2rem' }}>
          Burger: {rating.burger} <br></br>
          Rating: {rating.rating} <br></br>
          Service: {serviceRating} <br></br>
          Ambience: {ambienceRating} <br></br>
          Comment: {rating.comment} 
        </Card.Text>
      </Card.Body>
    </Card>
    </Col>
    );
}
function getCardColor(rating) {
    var color = "success"
    if (rating < 20) {
        color = "danger"
    } else if(rating < 50) {
        color = "warning"
    } else if ( rating < 70) {
        color = "dark"
    }
    return color;
}

function NoRatingAlert(props) {
    return (
        <div className="alert-container">
        <Alert variant="danger">
        <Alert.Heading>Oh no <FontAwesomeIcon icon={faHeartBroken} /></Alert.Heading>
        <p>
            There are currently no reviews for {props.restaurant}
        </p>
        <img className="alert-image" src="./sad.gif"></img>
        <Link to={ROUTES.RESTAURANT_LIST}>
        <Button variant="dark" className="alert-button" ><FontAwesomeIcon icon={faChevronLeft} /> Back</Button>
        </Link>
        <Button className="alert-button" to={ROUTES.RATING + '?restaurant=' + formatUrl(props.restaurant)} as={Link} variant="success"><FontAwesomeIcon icon={faPlusCircle} /> Rating</Button>
      </Alert>
      </div>
    )
}

function SuccessModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Successfully
        </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img className="success-image" src="./source.gif"></img>
            </Modal.Body>
            <Modal.Footer>
                <Button className="success-button" onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

function formatUrl(string) {
    return string.split('&').join('%26');
  }

const condition = authUser => !!authUser;
export default withAuthorization(condition)(withFirebase(RatingListPage));