import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, withRouter } from 'react-router-dom';

import Navigation from '../Navigation/Navigation';
import LandingPage from '../Landing/landing';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home/Home';
import AccountPage from '../Account/Account';
import AdminPage from '../Admin/Admin';
import RestaurantPage from '../RestaurantList';
import BurgermapPage from '../Burgermap';
import NotFoundPage from '../NotFound';
import UnderConstruction from '../UnderConstruction';
import RatingPage from '../Rating';
import RatingListPage from '../RatingList';
import WelcomePage from '../Welcome';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

import './app.scss';
import { Form, Button, Alert, Spinner } from 'react-bootstrap';
import RestaurantList from '../RestaurantList';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authUser: null,
      loading: true,
      restaurantList: [],
      ratingList: []
    };
  }

  compare(a, b) {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  }

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged(
      authUser => {
        authUser
          ? this.setState({ authUser })
          : this.setState({ authUser: null });
        if (this.state.authUser != null) {
          // load Data
          // console.log('Data would be loaded'); 
          this.props.firebase.restaurantList().on('value', snapshot => {
            const restaurantObject = snapshot.val();
            // console.log(restaurantObject);
            // this.setState({ restaurantList: restaurantObject});
            // console.log(this.state.restaurantList);
            const restaurantList = Object.keys(restaurantObject).map(key => ({
              ...restaurantObject[key],
              uid: key,
            }));
            this.setState({ restaurantList: restaurantList.sort(this.compare) });

            console.log(RestaurantList);
            this.setState({ loading: false });
            // get Location
            // const apikey = "AIzaSyCsOACXxw01KIxADvyjpY9dscAmIbTfYTY"
            // restaurantList.forEach(rest => {
            //   const currentRestaurant = {
            //     id: rest.uid,
            //     name: rest.name,
            //     location: rest.location,
            //     homepage_url: rest.homepage_url,
            //     geometry: {
            //       lat: '',
            //       lng: '',
            //     }
            //   }
            //   debugger;
            //   console.log(currentRestaurant);
            //   const name = currentRestaurant.name;
            //   const location = currentRestaurant.location;
            //   const homepage_url = currentRestaurant.homepage_url;
            //   // const lat = currentRestaurant1.geometry.lat;
            //   // const lng = currentRestaurant1.geometry.lng;
            //   // this.props.firebase
            //   //   .restaurant(rest1.uid)
            //   //   .set({
            //   //     name,
            //   //     location,
            //   //     homepage_url,
            //   //     geometry : {
            //   //       lat,
            //   //       lng
            //   //     }
            //   //   })
            //   // console.log(currentRestaurant);
            //   const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${location}&key=${apikey}`;
            //   fetch(url)
            //     .then(res => {
            //       res.json().then(body => {
            //         currentRestaurant.geometry.lat = body.results[0].geometry.location.lat;
            //         currentRestaurant.geometry.lng = body.results[0].geometry.location.lng;
            //         console.log(currentRestaurant);

            //         // update firebase data
            //         this.props.firebase
            //           .restaurant(currentRestaurant.id)
            //           .set({
            //             name: currentRestaurant.name,
            //             location: currentRestaurant.location,
            //             homepage_url: currentRestaurant.homepage_url,
            //             geometry: {
            //               lat: currentRestaurant.geometry.lat,
            //               lng: currentRestaurant.geometry.lng
            //             }
            //           })
            //         console.log(body.results[0].geometry.location.lat);
            //         console.log(body.results[0].geometry.location.lng);
            //       })
            //     })
            // });
            // console.log(restaurantList);
            // this.setState({ restaurantList: restaurantList });
            // this.setState({ loading: false });
            // console.log('data loaded');


            // check if first Login
            // load Local storage

            // localStorage.setItem('firstLogin', true);



          });
        } else {
          console.log('Data wont be loaded');
        }

        this.setState({ loading: false });
      },
    );


  }
  componentWillUnmount() {
    this.listener();
  }
  render() {
    const { loading } = this.state;
    return (
      <>
        {loading && <div className="spinner-app-container"><Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner></div>}
        {
          !loading &&
          <AuthUserContext.Provider value={this.state.authUser}>
            <Router>
              <div>
                <Navigation authUser={this.state.authUser} />
                <Switch>
                  <Redirect exact from="/" to={ROUTES.LANDING} />
                  <Route exact path={ROUTES.LANDING} component={() => <LandingPage authUser={this.state.authUser} />} />
                  <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
                  <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
                  <Route
                    exact
                    path={ROUTES.PASSWORD_FORGET}
                    component={PasswordForgetPage}
                  />
                  <Route exact path={ROUTES.RESTAURANT_LIST} component={() => <RestaurantPage restaurantList={this.state.restaurantList} />} />
                  <Route exact path={ROUTES.HOME} component={HomePage} />
                  <Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
                  <Route exact path={ROUTES.ADMIN} component={AdminPage} />
                  <Route exact path={ROUTES.BURGER_MAP} component={() => <BurgermapPage restaurantList={this.state.restaurantList} />} />
                  <Route exact path={ROUTES.RATING} component={() => <RatingPage restaurantList={this.state.restaurantList} />} />
                  <Route exact path={ROUTES.RATING_LIST} component={() => <RatingListPage restaurantList={this.state.restaurantList} />} />
                  <Route exact path={ROUTES.WELCOME} component={WelcomePage} />
                  <Route exact path={ROUTES.UNDER_CONSTRUCTION} component={UnderConstruction} />
                  <Route path="*" component={NotFoundPage} />
                </Switch>
              </div>
            </Router>
            {this.state.authUser && <div className="margin-bottom"></div>}
          </AuthUserContext.Provider>
        }

      </>)
  }

};

export default withAuthentication(withFirebase(App));