import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';
import SignOutButton from '../SignOut/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faMapMarkerAlt, faHamburger, faPlusCircle, faAtom, faSignInAlt, faUserAlt } from '@fortawesome/free-solid-svg-icons';

import { Navbar, Nav, Form, Button } from 'react-bootstrap';

// css
import './Navigation.scss'
import { render } from '@testing-library/react';

const INITIAL_STATE = {
  authUser: {}
};
export class Navigation extends Component {
  // const Navigation = ({ authUser }) => 


  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.setState({ authUser: this.props.authUser });
  }

  handleTabClick(e) {
    // get the active node and remove active class
    document.getElementsByClassName("active")[0].classList.remove("active");
    e.currentTarget.classList.add("active");
  }

  render() {
    // const { authUser } = this.state;
    // console.log('authUser:', authUser);
    return (
      <div className="navbar-container">
        <Navbar fixed="top" bg="dark" variant="dark">
          <Navbar.Brand>
            <img
              alt=""
              src='./icon_hamburger.png'
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{' '}
          </Navbar.Brand>
          {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
          {/* <Navbar.Collapse id="responsive-navbar-nav"> */}
          <AuthUserContext.Consumer>
            {authUser =>
              authUser ?
                <>
                  <NavigationAuth />
                </>
                : <NavigationNonAuth />
            }
          </AuthUserContext.Consumer>
          {/* </Navbar.Collapse> */}
        </Navbar>
        {/* Tab Navigation */}
        <AuthUserContext.Consumer>
          {authUser =>
            authUser ?
              <div className="tabbar-container">
                <nav className="tabbar">
                  <ul>
                    <li id="list" onClick={() => tabbarActiveHandler("list")} class="tab-node active">
                      <Link  to={ROUTES.RESTAURANT_LIST}>
                        <a >
                          <FontAwesomeIcon icon={faHamburger} />
                        </a>
                      </Link>
                    </li>
                    <li id="map" onClick={() => tabbarActiveHandler("map")}>
                      <Link  to={ROUTES.BURGER_MAP}>
                        <a class="tab-node">
                          <FontAwesomeIcon icon={faMapMarkerAlt} />
                        </a>
                      </Link>
                    </li>
                    <li id="rating" onClick={() => tabbarActiveHandler("rating")}>
                      <Link  to={ROUTES.RATING}>
                        <a class="tab-node">
                          <FontAwesomeIcon icon={faPlusCircle} />
                        </a>
                      </Link>
                    </li>
                    <li id="profile" onClick={() => tabbarActiveHandler("profile")}>
                      <Link  to={ROUTES.ACCOUNT}>
                        <a class="tab-node">
                          <FontAwesomeIcon icon={faUserAlt} />
                        </a>
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
              : <></>
          }
        </AuthUserContext.Consumer>
      </div>
    );
  }

}


export function tabbarActiveHandler(id) {
    // get the active node and remove active class
    document.getElementsByClassName("active")[0].classList.remove("active");
    // set acive node
    document.getElementById(id).classList.add("active");
}

const NavigationAuth = () => (
  <>
    <Nav className="mr-auto">
      <Form className="center-button" inline>
        <SignOutButton />
      </Form>
    </Nav>
  </>
);
const NavigationNonAuth = () => (
  <>

    <Nav className="mr-auto">
      <Nav.Link as={Link} to={ROUTES.LANDING}>
        <FontAwesomeIcon icon={faAtom} /> Landing
      </Nav.Link>
    </Nav>
    <Form className="center-button" inline>
      <Link to={ROUTES.SIGN_IN}><Button variant="success"><FontAwesomeIcon icon={faSignInAlt} /> Login</Button></Link>
    </Form>
  </>

);

export default Navigation;


// <Nav className="mr-auto">
// <Nav.Link as={Link} to={ROUTES.RESTAURANT_LIST}>
//   <FontAwesomeIcon icon={faHamburger} /> Restaurant
// </Nav.Link>
// <Nav.Link as={Link} to={ROUTES.BURGER_MAP}>
//   <FontAwesomeIcon icon={faMapMarkerAlt} /> Map
// </Nav.Link>
// <Nav.Link as={Link} to={ROUTES.RATING}>
//   <FontAwesomeIcon icon={faPlusCircle} /> Rating
// </Nav.Link>

// <Nav.Link as={Link} to={ROUTES.ACCOUNT}>
//   <FontAwesomeIcon icon={faLock} /> Account
// </Nav.Link>
// </Nav>