export const LANDING = '/landing';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const RESTAURANT_LIST = '/restaurant-list';
export const BURGER_MAP = '/burgermap';
export const UNDER_CONSTRUCTION = "/construction";
export const RATING = "/rating";
export const RATING_LIST = "/rating-list";
export const WELCOME = "/welcome"