import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/messaging';
import * as ROUTES from '../../constants/routes';

const config = {
  apiKey: 'AIzaSyCsOACXxw01KIxADvyjpY9dscAmIbTfYTY',
  authDomain: 'burgerboard-39d00.firebaseapp.com',
  databaseURL: 'https://burgerboard-39d00.firebaseio.com',
  projectId: 'burgerboard-39d00',
  storageBucket: 'burgerboard-39d00.appspot.com',
  messagingSenderId: '616403751165',
  appId: '1:616403751165:web:e9ba44bffd50510d9c6e94'
};



class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.database();


    //  console.log(app.messaging.isSupported());
    // // check if Cloud Messaging is supported by the Browser
    // if (app.messaging.isSupported()) {
    //   // enable Cloud Messaging 
    //   this.messaging = app.messaging();
    //   this.messaging.requestPermission()
    //   .then(function() {
    //     debugger;
    //     console.log('permisson given')
    //     console.log(app.messaging().getToken());
    //     return app.messaging().getToken();
    //   })
    //   .then(function(token) {
    //     console.log(token);
    //   })
    //   .catch(function(err) {
    //     console.log('error:', err);
    //   })
    // } else {
    //   console.log('FCM not supported');
    // }
    
  }



  
  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => {
    this.auth.signOut();
  }

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** User API ***
  user = uid => this.db.ref(`users/${uid}`);
  users = () => this.db.ref('users');


  // *** Restaurant API ***
  restaurant = uid => this.db.ref(`restaurants/${uid}`);
  restaurantList = () => this.db.ref('restaurants');

  // *** Rating API ***
  rating = uid => this.db.ref(`ratings/${uid}`);
  ratingList = () => this.db.ref('ratings');

    // *** Message API ***
    message = uid => this.db.ref(`messages/${uid}`);
    messages = () => this.db.ref('messages');


    create_UUID(){
      var dt = new Date().getTime();
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = (dt + Math.random()*16)%16 | 0;
          dt = Math.floor(dt/16);
          return (c=='x' ? r :(r&0x3|0x8)).toString(16);
      });
      return uuid;
  }
  
}



export default Firebase;
