import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { Form, Button, Alert } from 'react-bootstrap';

const INITIAL_STATE = {
    id: '',
    name: '',
    location: '',
    homepage_url: '',
    error: null,
  };

export class AddRestaurant extends Component {
  
    constructor(props) {
      super(props);
      this.state = { ...INITIAL_STATE };
    }
  

    
    onSubmit = event => {
      const { name, location, homepage_url, id } = this.state;
      this.props.firebase
            .restaurant(id)
            .set({
              name,
              location,
              homepage_url
            })
            .then(() => {
              alert('success');
            });
    }
    onChange = event => {
      this.setState({ [event.target.name]: event.target.value });
    };
  
    render() {
      const { name, location, homepage_url,error, id } = this.state;
      return (
        <div className="restaurant-container">
          <Form onSubmit={this.onSubmit}>
            <h1>Add Restaurant</h1>
            <Form.Group controlId="formBasicId">
              <Form.Label>ID</Form.Label>
              <Form.Control
                name="id"
                value={id}
                onChange={this.onChange}
                type="text"
           />
           </Form.Group>
            <Form.Group controlId="formBasicName">
              <Form.Label>Restaurant Name</Form.Label>
              <Form.Control
                name="name"
                value={name}
                onChange={this.onChange}
                type="text"
           />
          
            </Form.Group>
            <Form.Group controlId="formBasicLocation">
              <Form.Label>Location</Form.Label>
              <Form.Control
                name="location"
                value={location}
                onChange={this.onChange}
                type="text"
                 />
            </Form.Group>
            <Form.Group controlId="formBasicHomepage">
              <Form.Label>Homepage URL</Form.Label>
              <Form.Control
                name="homepage_url"
                value={homepage_url}
                onChange={this.onChange}
                type="text"
                 />
            </Form.Group>
            <Button block variant="success" type="submit">
              ADD
        </Button>
            {error &&
              <Alert variant="danger">
                <p>{error.message}</p>
              </Alert>
            }
          </Form>
        </div>
      )
    }
  }

export default withFirebase(AddRestaurant);