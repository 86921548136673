import React from 'react';
import { withAuthorization } from '../Session';
import Image from './underconstruction.png';

import './underconstruction.scss';

const UnderConstruction = () => (
    <div class="underconstruction-page">
        <div className="site-wrapper">
            <div className="site-wrapper-inner">
                <div className="cover-container">
                    <div className="masthead clearfix">
                    </div>
                    <div className="inner cover">
                        <img src={Image} />
                        <h1 className="cover-heading">Under Construction</h1>
                        <p className="lead">Contact for more Information</p>
                        <p>bjoern.geerken@exxeta.com</p>
                    </div>
                    <div className="mastfoot">
                        <div className="inner">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const condition = authUser => !!authUser;
export default withAuthorization(condition)(UnderConstruction);